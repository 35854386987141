<!-- 运营示例图配置 -->
<template>
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-row :gutter="15">
        <el-col :span="6" style="display: flex;">
          <el-input
            placeholder="编码/示例图名称"
            v-model="where.keyword"
            clearable>
          </el-input>
          <el-button type="primary" icon="el-icon-search" style="background: #EBECF2!important;border-color: #EBECF2!important;color: #3F4157!important;margin-left: 20px;" @click="selectN">搜索</el-button>
        </el-col>
        <el-col :span="18" style="display: flex;justify-content: flex-end;">
            <el-button  v-auths="[`${$config.uniquePrefix}operate:exampleFigureConfiguration:save`]"   style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;" @click="openEdit(null)" >
              <i class="el-icon-tianjia" />
              <span style="vertical-align: middle" >添加示例图</span>
          </el-button>
        </el-col>
      </el-row>

      <div class="margin-shang">
        <!-- 数据表格 -->
        <my-table
          ref="myTable"
          :columns="columns"
          :tableData="List"
          :headerCellStyle="headerCellStyle"
          :total="total"
          :customsFromWhere="where"
          :loading="loading"
          @select="select"
          @ChangeSize="ChangeSize"
          @currentChange="currentChange"
        >
          <!--自定义列显示 示例-->
          <template v-slot:sample_graph_path= "{ scope }">
            <div v-viewer class="tabBox_img">
              <img :src="scope.row.sample_graph_path">
            </div>
          </template>

          <template v-slot:status= "{ scope }">
            <el-tag v-if="scope.row.status === 0" type="success">已启用</el-tag>
            <el-tag v-else type="danger">已禁用</el-tag>
          </template>
          <!-- 是否归属系统 -->
          <template v-slot:is_system_label= "{ scope }">
             {{scope.row.is_system_label}}
          </template>
          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link :underline="false" type="warning" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}operate:exampleFigureConfiguration:info`]" >编辑</el-link>
            <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row) ">
              <el-link slot="reference" :underline="false" style="margin-left: 10px;" v-auths="[`${$config.uniquePrefix}operate:exampleFigureConfiguration:delete`]">删除</el-link>
            </el-popconfirm>
          </template>
        </my-table>
      </div>

    </el-card>
    
    <!--添加示例图弹窗组件-->
    <addSampleImage-edit v-if="showEdit == true" :data="current" :visible.sync="showEdit" @done="getList" />
    
  </div>
</template>

<script>
// 引入添加示例图弹窗组件
import addSampleImageEdit from './components/addSampleImage-edit.vue'
// 添加接口
import {Delsamples, getsamples} from "@/api/operate";

export default {
  //注册组件
  components: {addSampleImageEdit},
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
        keyword: '', // 搜索关键字
      },
      // 表格数据
      List: [],
      // 表格数据备份
      List_back: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "索引",
          prop: "uniques",
          isShow: true
        },
        //coding
        {
          label: "编码",
          prop: "coding",
          isShow: true
        },
        {
          label: "名称",
          prop: "sample_graph_name",
          isShow: true
        },
        {
          label: "示例图",
          prop: "sample_graph_path",
          slot: 'sample_graph_path',
          isShow: true
        },
        {
          label: "状态",
          prop: "status",
          slot: 'status',
          isShow: true
        },
        {
          label: "归属系统",
          prop: "is_system_label",
          slot: 'is_system_label',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          fixed:"right",
          slot: "operationSlot",
          isShow: true
        },
      ],

      //添加示例图
      current:null,
      showEdit:false,
    }
  },

  mounted() {
    //获取列表数据
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getsamples(this.where).then(async (res) => {
        // 获取列表数据
        this.total = res.data.count;
        //把表格数据赋值为表格数据
        this.List = res.data.list;
        this.List_back = res.data.list;
        console.log(this.List);
        // 关闭加载动画
        this.loading = false;
      }).catch((res) => {
        //提示错误信息
        this.$Message.error(res.msg);
        //关闭加载动画
        this.loading = false;
      })
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },

    //删除
    del(item){
      // 根据id删除指定条数
      Delsamples(item.id).then(res => {
        console.log(res)
        //判断code是否为200
        if(res.code === 200){
          this.$message.success(res.msg);
          // 获取列表数据
          this.getList();
        }else {
          // 获取列表数据
          this.$message.success(res.msg);
        }
      }).catch(e => {
        //删除失败提示的信息
        this.$message.error(e.msg)
      })
    },

    //显示编辑弹窗
    openEdit(item){
      //判断是否为null,为null则添加，不为null则修改
      this.current = item;
      // 控制弹窗显示
      this.showEdit = true;
    },

    //搜索
    selectN(){
      getsamples(this.where).then(async (res) => {
        // 获取列表数据
        this.total = res.data.count;
        //把表格数据赋值为表格数据
        this.List = res.data.list;
        this.List_back = res.data.list;
        console.log(this.List);
        // 关闭加载动画   
      }).catch((res) => {
        //提示错误信息
        this.$Message.error(res.msg);
        //关闭加载动画
      })
     
    }
  }
}
</script>

<style lang="scss" scoped>
  .chaochu{
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .margin-shang{
    margin-top: 20px;
  }

  .tabBox_img {
    width: 100px;
    height: 80px;
    border-radius: 4px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
